<app-nav-bar-after-login tab="shop"></app-nav-bar-after-login>

<div class="bg-white-bgGrey pb-9 min-h-[85vh] pt-24">
  <div class="container" *ngIf="!(isLoadingPage | async);else placeholder">
    <div class="w-fit">
      <div class="flex items-center gap-3 mb-8 cursor-pointer hover:underline" (click)="goBack()">
        <fa-icon class="fa-icon-div text-blue-ctaBlue" [icon]="['far','arrow-left']"></fa-icon>
        <p>Go back to shop</p>
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-3 gap-6" *ngIf="car">
      <div class="flex flex-col gap-6 col-span-1 md:col-span-2">
        <div class="bg-white">
          <app-image-slider [images]="images"></app-image-slider>
        </div>

        <!-- offer -->
        <app-main-content-layout *ngIf="offer">
          <p class="font-bold mb-9">Offer</p>

          <div class="flex flex-wrap gap-6 text-sm items-center">
            <p [ngClass]="'offer-status-' + offer.offerStatus" class="py-2 px-3">{{offer.offerStatus}}</p>

            <p class="text-blue-ctaBlue">€{{offer.value | number}}</p>

            <p>Offer expiration date: {{offer.expiresOn | date: 'yyyy-MM-dd'}}</p>
          </div>
        </app-main-content-layout>

        <!-- market data -->
        <!-- <app-main-content-layout
          *ngIf="car!.spotPriceExTax && car!.spotPriceExTax - car!.sellingPrice > 0 && car!.spotPriceExTax - car!.sellingPrice <= 3000">
          <p class="font-bold mb-9">Market data in {{storeCountry}}</p>

          <div class="flex flex-wrap gap-9 text-sm">
            <div class="flex items-center gap-3">
              <fa-icon class="text-blue-ctaBlue bg-white-bgGrey px-3 py-2 text-base rounded"
                [icon]="['far','chart-line']"></fa-icon>
              <p class="text-white-greyText">Sales factor</p>
              <p class="text-orange">
                {{!isNaN(car.salesFactor)? (car.salesFactor | number) : 'No data'}}
              </p>
            </div>

            <div class="flex items-center gap-3">
              <fa-icon class="text-blue-ctaBlue bg-white-bgGrey px-3 py-2 text-base rounded"
                [icon]="['fas','tag']"></fa-icon>
              <p class="text-white-greyText">Retail price ex VAT</p>
              <p>€{{car.spotPriceExTax | number}}</p>
            </div>

            <div class="flex items-center gap-3">
              <fa-icon class="text-blue-ctaBlue bg-white-bgGrey px-3 py-2 text-base rounded"
                [icon]="['fas','signal']"></fa-icon>
              <p class="text-white-greyText">Online volume</p>
              <p>{{car.carsOnline | number}}</p>
            </div>

            <div class="flex items-center gap-3">
              <fa-icon class="text-blue-ctaBlue bg-white-bgGrey px-3 py-2 text-base rounded"
                [icon]="['fas','calendar-days']"></fa-icon>
              <p class="text-white-greyText">Cars sold last 30 days</p>
              <p>{{car.carsSold30Days | number}}</p>
            </div>

            <div class="flex items-center gap-3">
              <fa-icon class="text-blue-ctaBlue bg-white-bgGrey px-3 py-2 text-base rounded"
                [icon]="['fas','scale-balanced']"></fa-icon>
              <p class="text-white-greyText">Estimated profit</p>
              <p class="text-green">€{{car.spotPriceExTax - car.sellingPrice | number}}</p>
            </div>
          </div>
        </app-main-content-layout> -->

        <!-- Technical data -->
        <app-main-content-layout>
          <div class="flex flex-col gap-9">
            <p class="font-bold">Technical data</p>

            <div class="grid grid-cols-1 sm:grid-cols-2 gap-9 text-sm">
              <div class="flex flex-col gap-4 sm:pr-9 sm:border-r border-white-lines">
                <div class="flex justify-between">
                  <p class="text-white-greyText">Make</p>
                  <p>{{car.make}}</p>
                </div>
                <div class="flex justify-between">
                  <p class="text-white-greyText">Model</p>
                  <p>{{car.model}}</p>
                </div>
                <div class="flex justify-between">
                  <p class="text-white-greyText">Variant</p>
                  <p>{{car.variant}}</p>
                </div>
                <div class="flex justify-between" *ngIf="car.trim">
                  <p class="text-white-greyText">Trim</p>
                  <p>{{car.trim}}</p>
                </div>
                <div class="flex justify-between">
                  <p class="text-white-greyText">Model year</p>
                  <p>{{car.manufactureYear}}</p>
                </div>
                <div class="flex justify-between">
                  <p class="text-white-greyText">Location</p>
                  <p>{{car.locationName}}</p>
                </div>
                <div class="flex justify-between">
                  <p class="text-white-greyText">Damages</p>
                  <p>€{{car.damages | number}}</p>
                </div>
                <div class="flex justify-between">
                  <p class="text-white-greyText">VIN</p>
                  <p>{{car.vin}}</p>
                </div>
              </div>

              <div class="flex flex-col gap-4">
                <div class="flex justify-between">
                  <p class="text-white-greyText">Engine power</p>
                  <p>{{car.enginePower | number}}</p>
                </div>
                <div class="flex justify-between" *ngIf="car.engineVolume">
                  <p class="text-white-greyText">Engine volume</p>
                  <p>{{car.engineVolume | number}}</p>
                </div>
                <div class="flex justify-between">
                  <p class="text-white-greyText">Fuel type</p>
                  <p>{{car.fuelType}}</p>
                </div>
                <div class="flex justify-between">
                  <p class="text-white-greyText">Gearbox</p>
                  <p>{{car.gearbox}}</p>
                </div>
                <div class="flex justify-between">
                  <p class="text-white-greyText">Mileage</p>
                  <p>{{car.mileage | number}}</p>
                </div>
                <div class="flex justify-between">
                  <p class="text-white-greyText">First registration</p>
                  <p>{{car.firstReg}}</p>
                </div>
                <div class="flex justify-between">
                  <p class="text-white-greyText">Registration Number</p>
                  <p>{{car.regNo}}</p>
                </div>
              </div>
            </div>

            <div class="flex flex-wrap gap-3 text-sm">
              @if(car.color){
              <div class="flex items-center gap-3 bg-white-bgGrey p-3">
                <p class="text-white-greyText">Color</p>
                <p>{{car.color}}</p>
              </div>
              }

              <div class="flex items-center gap-3 bg-white-bgGrey p-3">
                <p class="text-white-greyText">Body type</p>
                <p>{{car.bodyType}}</p>
              </div>

              <div class="flex items-center gap-3 bg-white-bgGrey p-3">
                <p class="text-white-greyText">Driving wheels</p>
                <p>{{car.drivingWheels}}</p>
              </div>

              <div class="flex items-center gap-3 bg-white-bgGrey p-3">
                <p class="text-white-greyText">CO2 NEDC</p>
                <p>{{car.co2NEDC}} g/km</p>
              </div>

              <div class="flex items-center gap-3 bg-white-bgGrey p-3">
                <p class="text-white-greyText">CO2 WLTP</p>
                <p>{{car.co2WLTP}} g/km</p>
              </div>
            </div>
          </div>
        </app-main-content-layout>

        <!-- equipments -->
        <div *ngIf="standardEquipments.length > 0">
          <app-main-content-layout id="standard-eq">
            <p class="mb-9 font-bold">Standard equipment</p>

            <div class="grid grid-cols-1 sm:grid-cols-2 gap-y-3">
              <div class="col-span-1 flex gap-3 cursor-default" *ngFor="let eq of standardEquipments">
                <fa-icon class="text-blue-ctaBlue" [icon]="['fas','circle-check']"></fa-icon>
                <p>{{eq}}</p>
              </div>
            </div>
          </app-main-content-layout>

          <div class="bg-blue-cardHover py-3 px-6 md:px-9 rounded-b w-full">
            <p class="text-blue-blueTeal text-sm">Disclaimer: Standard equipment list is based on a general description
              of the German Auto Market.</p>
          </div>
        </div>

        <!-- equipments -->
        <app-main-content-layout *ngIf="equipments.length > 0">
          <p class="mb-9 font-bold">Optional equipment</p>

          <div class="grid grid-cols-1 sm:grid-cols-2 gap-y-3">
            <div class="col-span-1 flex gap-3 cursor-default" *ngFor="let eq of equipments | slice:0:12">
              <fa-icon class="text-blue-ctaBlue" [icon]="['fas','circle-check']"></fa-icon>
              <p>{{eq}}</p>
            </div>
          </div>

          <app-dropdown-panel [panelId]="'moreEqPanel'" #moreEqPanel>
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-y-3 mt-3">
              <div class="col-span-1 flex gap-3 cursor-default"
                *ngFor="let eq of equipments | slice:12:equipments.length">
                <fa-icon class="text-blue-ctaBlue" [icon]="['fas','circle-check']"></fa-icon>
                <p>{{eq}}</p>
              </div>
            </div>
          </app-dropdown-panel>

          <div class="flex justify-center mt-3" *ngIf="equipments.length > 12">
            <app-white-btn (clickEmitter)="moreEqPanel.togglePanel()">
              {{moreEqPanel.expandedPanel ? 'Show less' : 'Show more'}}
            </app-white-btn>
          </div>
        </app-main-content-layout>

        <!-- documents -->
        <app-main-content-layout *ngIf="car.documents.length > 0">
          <p class="mb-9 font-bold">Documents</p>

          <div class="flex flex-wrap gap-3">
            <div class="flex gap-5 text-sm p-3 bg-white-bgGrey" *ngFor="let doc of car.documents">
              <p>{{doc.name}}</p>

              <a (click)="downloadFile(doc.id)" class="underline text-blue-700 cursor-pointer">see document</a>
            </div>
          </div>
        </app-main-content-layout>

        <!-- notes -->
        <app-main-content-layout *ngIf="car.otherInfo && car.otherInfo.length > 0">
          <p class="mb-9 font-bold">Notes</p>

          <div class="text-sm w-full">
            <p>{{car.otherInfo}}</p>
          </div>
        </app-main-content-layout>

        <!-- subheader -->
        <app-main-content-layout *ngIf="car.subHeader && car.subHeader.length > 0">
          <p class="mb-9 font-bold">SubHeader</p>

          <div class="text-sm w-full">
            <p>{{car.subHeader}}</p>
          </div>
        </app-main-content-layout>
      </div>

      <!-- shopping cart -->
      <div class="flex flex-col gap-9 bg-white p-6 md:px-9 rounded w-full h-fit sticky top-0">
        <div>
          <p class="font-bold">{{car.make}} {{car.model}}, {{car.variant}}</p>

          <p class="text-sm text-white-greyText mt-4">vehicle id :
            <span id="copy-id-btn" data-cy="copy-id-btn" [cdkCopyToClipboard]="car.carMainInfoId" (click)="copyAlert()"
              class="flex gap-4 cursor-pointer">
              {{car.carMainInfoId}}
              <fa-icon [icon]="['far','copy']" [matTooltip]="'Copy vehicle id'" matTooltipPosition="above"></fa-icon>
            </span>
          </p>
        </div>

        <div class="flex justify-between">
          <div>
            <p class="font-bold text-blue-ctaBlue mb-3">€{{car.sellingPrice | number}} <span
                class="font-normal text-xs text-white-600">{{vat}}</span></p>
          </div>

          <fa-icon (click)="addToFavs()"
            class="px-3 py-2 bg-white-bgGrey text-white-disabled rounded h-fit cursor-pointer" [icon]="['fas','heart']"
            [ngClass]="{'is-favorited':isFav}"></fa-icon>
        </div>

        <div>
          <app-dropdown [options]="storesDropdown" [control]="storeControl" id="store"
            placeholder="Select delivery store" label="Delivery store"></app-dropdown>

          <p class="text-sm mt-3 text-white-greyText">Transport included to selected store</p>
        </div>

        <div class="flex flex-col sm:flex-row md:flex-col items-center md:items-stretch gap-4 text-center"
          *ngIf="car!.carStatus != 'Reserved';else reserved">
          <app-blue-btn id="add-to-cart" (clickEmitter)="addToCart()">Add to cart</app-blue-btn>

          <app-white-btn *ngIf="!offer" [variant]="2" (clickEmitter)="openMakeOfferModal()">
            Make offer
          </app-white-btn>

          <app-white-btn
            *ngIf="offer && (offer.offerStatus === offerStatus.Expired || offer.offerStatus === offerStatus.Rejected)"
            (clickEmitter)="openCounterOfferModal(offer)" [variant]="2">
            Make counter offer
          </app-white-btn>

          <div class="flex flex-col gap-3 mt-4"
            *ngIf="offer && (offer.offerStatus === offerStatus.CounterOffer || offer.offerStatus === offerStatus.C2CNewOffer)">
            <app-green-btn id="accept-offer" (clickEmitter)="acceptOffer(offer)">
              <p>Accept offer</p>
            </app-green-btn>

            <app-white-btn (clickEmitter)="openCounterOfferModal(offer)" [variant]="2">Make counter
              offer</app-white-btn>

            <app-black-btn id="decline-offer" (clickEmitter)="declineOffer(offer)">Decline offer</app-black-btn>
          </div>
          <!-- <p class="text-blue-ctaBlue text-sm">Generate PDF offer</p> -->
        </div>

        <ng-template #reserved>
          <app-read-only>
            <p class="text-center">Reserved</p>
          </app-read-only>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<div class="bg-white py-9" *ngIf="carAlternatives.length > 0">
  <div class="container grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-5 gap-x-6 gap-y-9"
    *ngIf="!(isLoadingPage | async);else placeholderSuggCard">
    <div class="col-span-1 sm:col-span-3 xl:col-span-5 font-bold">
      <p>You might also like</p>
    </div>

    <app-car-suggestion-card class="col-span-1" *ngFor="let car of carAlternatives"
      [car]="car"></app-car-suggestion-card>
  </div>
</div>

<app-footer-after-login></app-footer-after-login>

<ng-template #placeholder>
  <div class="container grid grid-cols-1 md:grid-cols-3 gap-6 min-h-[85vh]">
    <div class="col-span-1 md:col-span-2 flex flex-col gap-6">
      <div class="flex justify-center">
        <img class="rounded object-contain opacity-30" src="assets/photos/car-placeholder.png" alt="">
      </div>

      <app-main-content-layout>
        <div class="flex flex-col gap-6">
          <div class="placeholder-animation w-full h-6" *ngFor="let i of [].constructor(4)"></div>
        </div>
      </app-main-content-layout>

      <app-main-content-layout>
        <div class="flex flex-col gap-6">
          <div class="placeholder-animation w-full h-6" *ngFor="let i of [].constructor(4)"></div>
        </div>
      </app-main-content-layout>
    </div>

    <div class="flex flex-col gap-6 bg-white p-6 md:px-9 rounded w-full h-fit">
      <div class="placeholder-animation w-full h-6" *ngFor="let i of [].constructor(4)"></div>
    </div>
  </div>
</ng-template>


<ng-template #placeholderSuggCard>
  <div class="container grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-5 gap-6">
    <div class="col-span-1 sm:col-span-3 xl:col-span-5 font-bold">
      <p>You might also like</p>
    </div>

    <div class="col-span-1 rounded mt-6" *ngFor="let i of [].constructor(3)">
      <div class="flex justify-center">
        <img class="rounded object-contain opacity-30" src="assets/photos/car-placeholder.png" alt="">
      </div>

      <div class="flex flex-col gap-6 p-6">
        <div class="placeholder-animation w-full h-6" *ngFor="let i of [].constructor(4)"></div>
      </div>
    </div>
  </div>
</ng-template>
